import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class ValueReceiver extends Component {
    constructor() {
        super();
        this.state = {};
        this.receiveMessage = this.receiveMessage.bind(this);
        window.addEventListener('message', this.receiveMessage, false);
    }
    receiveMessage(event) {
        console.info('receive value from parent!');
        const d = {...event.data};
        this.setState(d);
        this.props.setProps({value: d});
    }

    render() {
        const {id, label, value} = this.props;

        return (
            <div style={{display: 'none'}} id={id}>
                <div>{label}</div>
                <div>propValue: {JSON.stringify(value)}</div>
                <div>thisState: {JSON.stringify(this.state)}</div>
            </div>
        );
    }
}

ValueReceiver.defaultProps = {};

ValueReceiver.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * A label that will be printed when this component is rendered.
     */
    label: PropTypes.string.isRequired,

    /**
     * The value displayed in the input.
     */
    value: PropTypes.object,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};